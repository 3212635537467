import React, { useState } from 'react';
import { migrateExistingUsers, updateUserIntercom, syncAllUsersToIntercom, generateUserHash } from '../utils/intercom';
import { track } from '../utils/posthog';
import { useToast } from '../context/ToastContext';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

const APIConnections = () => {
  const { addToast } = useToast();
  const [intercomStatus, setIntercomStatus] = useState('Unknown');
  const [posthogStatus, setPosthogStatus] = useState('Unknown');
  const [lastUpdated, setLastUpdated] = useState(null);
  const [logs, setLogs] = useState([]);
  const [userId, setUserId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingHash, setIsGeneratingHash] = useState(false);
  const [syncProgress, setSyncProgress] = useState(null);
  const [syncResults, setSyncResults] = useState(null);
  const [syncDetails, setSyncDetails] = useState({
    inProgress: false,
    currentUser: null,
    processedUsers: []
  });
  const [selectedUserData, setSelectedUserData] = useState(null);

  const addLog = (message, type = 'info') => {
    setLogs((prevLogs) => [{
      message,
      timestamp: new Date().toLocaleString(),
      type
    }, ...prevLogs]);
  };

  const checkIntercomStatus = () => {
    try {
      if (typeof window.Intercom === 'function') {
        setIntercomStatus('Connected');
        addLog('Intercom is properly initialized and connected', 'success');
        addToast('Intercom connection verified', 'success');
      } else {
        setIntercomStatus('Not Connected');
        addLog('Intercom is not properly initialized', 'error');
        addToast('Intercom is not properly initialized', 'error');
      }
    } catch (error) {
      setIntercomStatus('Error');
      addLog(`Error checking Intercom status: ${error.message}`, 'error');
      addToast('Error checking Intercom status', 'error');
    }
  };

  const handleUpdateSpecificUser = async (e) => {
    e.preventDefault();
    if (!userId.trim()) {
      addToast('Please enter a user ID', 'error');
      return;
    }

    setIsLoading(true);
    try {
      // Try to find user in creators collection
      let userDoc = await getDoc(doc(db, 'creators', userId));
      let userType = 'creator';

      // If not found in creators, try brands collection
      if (!userDoc.exists()) {
        userDoc = await getDoc(doc(db, 'brands', userId));
        userType = 'brand';
      }

      if (!userDoc.exists()) {
        addToast('User not found in either creators or brands collection', 'error');
        addLog(`User not found: ${userId}`, 'error');
        return;
      }

      const userData = userDoc.data();
      addLog(`Found user in ${userType}s collection: ${userData.name || userData.email}`, 'info');
      
      await updateUserIntercom(userId);
      addToast('User data updated in Intercom successfully', 'success');
      setLastUpdated(new Date().toLocaleString());
      addLog(`Successfully updated Intercom data for ${userType}: ${userData.name || userData.email}`, 'success');
    } catch (error) {
      addToast('Error updating user in Intercom: ' + error.message, 'error');
      addLog(`Error updating user in Intercom: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleMigrateUsers = async (e) => {
    // Prevent default form submission
    e.preventDefault();
    
    setIsLoading(true);
    try {
      addLog('Starting user migration to Intercom...', 'info');
      await migrateExistingUsers();
      addToast('User migration to Intercom completed successfully', 'success');
      setLastUpdated(new Date().toLocaleString());
      addLog('User migration to Intercom completed successfully', 'success');
    } catch (error) {
      addToast('Error migrating users to Intercom: ' + error.message, 'error');
      addLog(`Error migrating users to Intercom: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSyncAllUsers = async () => {
    try {
      setIsLoading(true);
      setSyncProgress(null);
      setSyncResults(null);
      setSyncDetails({
        inProgress: true,
        currentUser: null,
        processedUsers: []
      });

      addLog('Starting full user sync to Intercom...', 'info');

      const results = await syncAllUsersToIntercom((progress) => {
        setSyncProgress(progress);
        if (progress.currentUser) {
          setSyncDetails(prev => ({
            ...prev,
            currentUser: progress.currentUser,
            processedUsers: [...prev.processedUsers, progress.currentUser]
          }));
        }
        addLog(`Synced ${progress.type === 'creator' ? 'Creator' : 'Brand'} ${progress.current}/${progress.total}`, 'info');
      });

      setSyncResults(results);
      addLog('Sync completed. Summary:', 'success');
      addLog(`Creators: ${results.creators.success} succeeded, ${results.creators.failed} failed`, 'info');
      addLog(`Brands: ${results.brands.success} succeeded, ${results.brands.failed} failed`, 'info');
      addToast('All users synced to Intercom successfully', 'success');
    } catch (error) {
      addLog(`Error syncing users to Intercom: ${error.message}`, 'error');
      addToast('Error syncing users to Intercom', 'error');
    } finally {
      setIsLoading(false);
      setSyncDetails(prev => ({ ...prev, inProgress: false }));
    }
  };

  const verifyHash = async (userId) => {
    try {
      // Get user data first
      let userDoc = await getDoc(doc(db, 'creators', userId));
      let userType = 'creator';

      if (!userDoc.exists()) {
        userDoc = await getDoc(doc(db, 'brands', userId));
        userType = 'brand';
      }

      if (!userDoc.exists()) {
        addLog(`User not found: ${userId}`, 'error');
        return;
      }

      const userData = userDoc.data();
      
      // Generate hash using both ID and email
      const idHash = await generateUserHash(userId);
      const emailHash = await generateUserHash(null, userData.email);

      addLog(`User Email: ${userData.email}`, 'info');
      addLog(`ID Hash: ${idHash}`, 'success');
      addLog(`Email Hash: ${emailHash}`, 'success');
      
      addLog('Hash verification completed', 'success');
    } catch (error) {
      addLog(`Error generating hash: ${error.message}`, 'error');
    }
  };

  const handleVerifyHash = async () => {
    setIsGeneratingHash(true);
    try {
      await verifyHash(userId);
    } finally {
      setIsGeneratingHash(false);
    }
  };

  const debugEnvironmentVars = () => {
    const envVarsPresent = {
      INTERCOM_SECRET_KEY: !!process.env.REACT_APP_INTERCOM_SECRET_KEY,
      INTERCOM_APP_ID: !!process.env.REACT_APP_INTERCOM_APP_ID,
      NODE_ENV: process.env.NODE_ENV
    };
    addLog(`Environment variables check completed`, 'info');
    addLog(`INTERCOM_APP_ID present: ${envVarsPresent.INTERCOM_APP_ID}`, 'info');
    addLog(`INTERCOM_SECRET_KEY present: ${envVarsPresent.INTERCOM_SECRET_KEY}`, 'info');
    addLog(`NODE_ENV: ${envVarsPresent.NODE_ENV}`, 'info');
  };

  const DataModal = ({ data, onClose }) => {
    if (!data) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Synced Data Details</h3>
            <button 
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          <div className="space-y-4">
            <div>
              <h4 className="font-medium mb-2">Basic Info</h4>
              <div className="grid grid-cols-2 gap-2">
                <div>User ID: {data.user_id}</div>
                <div>Email: {data.email}</div>
                <div>Name: {data.name}</div>
                <div>Created At: {new Date(data.created_at * 1000).toLocaleString()}</div>
              </div>
            </div>

            {data.company && (
              <div>
                <h4 className="font-medium mb-2">Company Info</h4>
                <div className="grid grid-cols-2 gap-2">
                  <div>Company ID: {data.company.company_id}</div>
                  <div>Name: {data.company.name}</div>
                  <div>Website: {data.company.website}</div>
                  <div>Industry: {data.company.industry}</div>
                </div>
              </div>
            )}

            <div>
              <h4 className="font-medium mb-2">Custom Attributes</h4>
              <div className="grid grid-cols-2 gap-2">
                {Object.entries(data.custom_attributes).map(([key, value]) => (
                  <div key={key}>
                    <span className="font-medium">{key}:</span>{' '}
                    {typeof value === 'boolean' ? value.toString() : value}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SyncDetailsView = () => {
    if (!syncDetails.inProgress && !syncDetails.processedUsers.length) return null;

    return (
      <div className="mt-4 p-4 bg-white rounded shadow">
        <h3 className="font-semibold mb-4">Sync Details</h3>
        
        {syncDetails.currentUser && (
          <div className="mb-4 p-3 bg-blue-50 rounded">
            <h4 className="font-medium">Currently Processing:</h4>
            <div className="ml-4">
              <p>Type: {syncDetails.currentUser.userType}</p>
              <p>Email: {syncDetails.currentUser.email}</p>
              <p>Name: {syncDetails.currentUser.name}</p>
            </div>
          </div>
        )}

        <div className="max-h-96 overflow-y-auto">
          <h4 className="font-medium mb-2">Processed Users:</h4>
          {syncDetails.processedUsers.map((user, index) => (
            <div 
              key={index} 
              className={`mb-2 p-2 rounded ${
                user.status === 'success' ? 'bg-green-50' : 'bg-red-50'
              }`}
            >
              <div className="flex justify-between">
                <span>{user.userType}: {user.email}</span>
                <span className={user.status === 'success' ? 'text-green-600' : 'text-red-600'}>
                  {user.status}
                </span>
              </div>
              {user.error && (
                <p className="text-red-500 text-sm mt-1">{user.error}</p>
              )}
              {user.syncedData && (
                <div className="mt-1 text-sm">
                  <button
                    onClick={() => setSelectedUserData(user.syncedData)}
                    className="text-blue-500 hover:underline"
                  >
                    View Synced Data
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>

        {selectedUserData && (
          <DataModal 
            data={selectedUserData} 
            onClose={() => setSelectedUserData(null)} 
          />
        )}
      </div>
    );
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">API Connections</h1>
      <div className="space-y-4">
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Intercom Status</h2>
          <p>Status: {intercomStatus}</p>
          <div className="flex flex-wrap gap-2 mt-2">
            <button 
              onClick={checkIntercomStatus} 
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={isLoading}
              type="button"
            >
              Check Status
            </button>
            <button 
              onClick={handleMigrateUsers} 
              className="bg-green-500 text-white px-4 py-2 rounded"
              disabled={isLoading}
              type="button"
            >
              {isLoading ? 'Processing...' : 'Migrate Users'}
            </button>
            <button 
              onClick={handleSyncAllUsers} 
              className="bg-green-500 text-white px-4 py-2 rounded"
              disabled={isLoading}
              type="button"
            >
              {isLoading ? 'Syncing...' : 'Sync All Users'}
            </button>
            <button 
              onClick={handleVerifyHash}
              className="bg-purple-500 text-white px-4 py-2 rounded"
              disabled={!userId || isLoading || isGeneratingHash}
              type="button"
            >
              {isGeneratingHash ? 'Generating...' : 'Verify Hash'}
            </button>
            <button 
              onClick={debugEnvironmentVars}
              className="bg-orange-500 text-white px-4 py-2 rounded"
              type="button"
            >
              Debug Env Vars
            </button>
          </div>
          
          <div className="mt-4 border-t pt-4">
            <h3 className="text-md font-semibold mb-2">Update Specific User</h3>
            <form 
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateSpecificUser(e);
              }} 
              className="flex items-center space-x-2"
            >
              <input
                type="text"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                placeholder="Enter User ID"
                className="border rounded px-3 py-2 flex-1"
                disabled={isLoading}
              />
              <button
                type="submit"
                className="bg-yellow-500 text-white px-4 py-2 rounded"
                disabled={isLoading}
              >
                {isLoading ? 'Updating...' : 'Update User'}
              </button>
            </form>
          </div>
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Last Updated</h2>
          <p>{lastUpdated ? `Last updated at: ${lastUpdated}` : 'Not updated yet'}</p>
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Logs</h2>
          <div className="max-h-96 overflow-y-auto">
            {logs.length > 0 ? (
              logs.map((log, index) => (
                <div 
                  key={index} 
                  className={`border-b py-2 ${
                    log.type === 'error' ? 'text-red-600' :
                    log.type === 'success' ? 'text-green-600' :
                    'text-gray-600'
                  }`}
                >
                  <p>{log.timestamp}: {log.message}</p>
                </div>
              ))
            ) : (
              <p>No logs available.</p>
            )}
          </div>
        </div>

        {syncProgress && (
          <div className="mt-4 p-4 bg-gray-100 rounded">
            <h3 className="font-semibold">Sync Progress</h3>
            <div className="mt-2">
              <div className="flex justify-between mb-1">
                <span>{syncProgress.type === 'creator' ? 'Creators' : 'Brands'}</span>
                <span>{syncProgress.current}/{syncProgress.total}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full" 
                  style={{ width: `${(syncProgress.current / syncProgress.total) * 100}%` }}
                ></div>
              </div>
            </div>
          </div>
        )}

        <SyncDetailsView />

        {syncResults && (
          <div className="mt-4 p-4 bg-white rounded shadow">
            <h3 className="font-semibold mb-4">Sync Results</h3>
            
            <div className="mb-4">
              <h4 className="font-medium">Creators</h4>
              <p>Total: {syncResults.creators.total}</p>
              <p className="text-green-600">Succeeded: {syncResults.creators.success}</p>
              <p className="text-red-600">Failed: {syncResults.creators.failed}</p>
            </div>

            <div className="mb-4">
              <h4 className="font-medium">Brands</h4>
              <p>Total: {syncResults.brands.total}</p>
              <p className="text-green-600">Succeeded: {syncResults.brands.success}</p>
              <p className="text-red-600">Failed: {syncResults.brands.failed}</p>
            </div>

            {(syncResults.creators.failed > 0 || syncResults.brands.failed > 0) && (
              <div className="mt-4">
                <h4 className="font-medium text-red-600">Failed Syncs</h4>
                <div className="max-h-40 overflow-y-auto mt-2">
                  {[...syncResults.creators.details, ...syncResults.brands.details]
                    .filter(detail => detail.status === 'failed')
                    .map((detail, index) => (
                      <div key={index} className="text-sm text-red-600 mb-1">
                        {detail.id}: {detail.error}
                      </div>
                    ))
                  }
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default APIConnections; 