import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { auth, db } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { initializeIntercom, updateIntercom, updateUserIntercom } from '../utils/intercom';
import { identify, track, setUserProperties } from '../utils/posthog';

const AuthContext = createContext(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [isApproved, setIsApproved] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const navigate = useNavigate();
  const isInitialMount = useRef(true);

  useEffect(() => {
    let mounted = true;
    let unsubscribe;

    const initializeAuth = async () => {
      unsubscribe = onAuthStateChanged(auth, async (user) => {
        try {
          if (user && mounted) {
            const [creatorDoc, brandDoc] = await Promise.all([
              getDoc(doc(db, 'creators', user.uid)),
              getDoc(doc(db, 'brands', user.uid))
            ]);

            if (!mounted) return;

            setCurrentUser(user);
            
            const brandData = brandDoc.exists() ? brandDoc.data() : null;
            const creatorData = creatorDoc.exists() ? creatorDoc.data() : null;
            
            const isUserAdmin = 
              (brandData?.isAdmin === true) || 
              (creatorData?.isAdmin === true);
            setIsAdmin(isUserAdmin);

            const currentPath = window.location.pathname;

            if (creatorDoc.exists()) {
              const creatorData = creatorDoc.data();
              
              await Promise.all([
                setUserType('creator'),
                setOnboardingCompleted(creatorData?.onboardingCompleted ?? false),
                setIsApproved(creatorData?.isApproved ?? false),
                setIsActive(creatorData?.isActive ?? true)
              ]);

              initializeIntercom({
                id: user.uid,
                email: user.email,
                name: user.displayName || '',
                role: 'creator',
                createdAt: user.metadata?.creationTime ? new Date(user.metadata.creationTime) : new Date()
              });

              identify(user.uid, {
                email: user.email,
                name: user.displayName,
                userType: 'creator'
              });

              if (creatorData) {
                setUserProperties({
                  onboardingCompleted: creatorData.onboardingCompleted ?? false,
                  isApproved: creatorData.isApproved ?? false,
                  isActive: creatorData.isActive ?? true,
                  lastLogin: new Date().toISOString()
                });
              }

              if (isInitialMount.current && 
                  !currentPath.includes('/admin') && 
                  !currentPath.includes('/profile') && 
                  !currentPath.includes('/creator/')) {
                requestAnimationFrame(() => {
                  navigate('/offers', { replace: true });
                  isInitialMount.current = false;
                });
              }

            } else if (brandDoc.exists()) {
              const brandData = brandDoc.data();
              
              await Promise.all([
                setUserType('brand'),
                setOnboardingCompleted(brandData?.onboardingCompleted ?? false),
                setIsApproved(brandData?.isApproved ?? false),
                setIsActive(brandData?.isActive ?? true)
              ]);

              initializeIntercom({
                id: user.uid,
                email: user.email,
                name: user.displayName || '',
                role: 'brand',
                createdAt: user.metadata?.creationTime ? new Date(user.metadata.creationTime) : new Date()
              });

              identify(user.uid, {
                email: user.email,
                name: user.displayName,
                userType: 'brand'
              });

              if (brandData) {
                setUserProperties({
                  onboardingCompleted: brandData.onboardingCompleted ?? false,
                  isApproved: brandData.isApproved ?? false,
                  isActive: brandData.isActive ?? true,
                  lastLogin: new Date().toISOString()
                });
              }

              if (isInitialMount.current && 
                  !currentPath.includes('/admin') && 
                  !currentPath.includes('/profile') && 
                  !currentPath.includes('/creator/')) {
                requestAnimationFrame(() => {
                  navigate('/dashboard', { replace: true });
                  isInitialMount.current = false;
                });
              }
            }

            track('user_logged_in', {
              method: 'firebase',
              userType: creatorDoc.exists() ? 'creator' : 'brand'
            });
          } else if (mounted) {
            setCurrentUser(null);
            setUserType(null);
            setIsAdmin(false);
            updateIntercom(null);
            isInitialMount.current = true;
          }
        } catch (error) {
          console.error('Auth error:', error);
          track('auth_error', { error: error.message });
        } finally {
          if (mounted) {
            setLoading(false);
            setInitialLoadComplete(true);
          }
        }
      });
    };

    initializeAuth();

    return () => {
      mounted = false;
      if (unsubscribe) unsubscribe();
    };
  }, [navigate]);

    useEffect(() => {
      if (!currentUser) {
        document.title = 'Welcome to Behave';
      }
    }, [currentUser]);

    const logout = async () => {
      try {
        await signOut(auth);
        setCurrentUser(null);
        setUserType(null);
        setIsAdmin(false);
        setOnboardingCompleted(false);
        setIsApproved(null);
        setIsActive(null);
        isInitialMount.current = true;
        localStorage.removeItem('user');
        updateIntercom(null);
      } catch (error) {
        track('logout_error', { error: error.message });
        throw error;
      }
    };

    const isReady = !loading && initialLoadComplete && 
      (!currentUser || (isApproved !== null && isActive !== null));

    const value = {
      currentUser,
      userType,
      isAdmin,
      onboardingCompleted,
      isApproved,
      isActive,
      setOnboardingCompleted,
      loading: !isReady,
      initialLoadComplete,
      logout
    };

    if (!isReady) {
      return (
        <div className="flex items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      );
    }

    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
};

export default AuthProvider;
