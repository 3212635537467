import { getDocs, collection, getDoc, doc, query, where } from 'firebase/firestore';
import { db } from '../firebase';

// Get environment variables with fallbacks
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || "ef788m81";
const INTERCOM_SECRET_KEY = process.env.REACT_APP_INTERCOM_SECRET_KEY;

// Validate environment variables on initialization
if (!INTERCOM_SECRET_KEY) {
  throw new Error('REACT_APP_INTERCOM_SECRET_KEY is not set in environment variables');
}

// Helper functions
const stringToUint8Array = (str) => {
  const encoder = new TextEncoder();
  return encoder.encode(str);
};

const arrayBufferToHex = (buffer) => {
  return Array.from(new Uint8Array(buffer))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
};

// Add this helper function to safely convert various date formats to Unix timestamp
const getUnixTimestamp = (date) => {
  if (!date) return undefined;

  try {
    // Handle Firestore Timestamp
    if (typeof date.toDate === 'function') {
      return Math.floor(date.toDate().getTime() / 1000);
    }
    
    // Handle Date object
    if (date instanceof Date) {
      return Math.floor(date.getTime() / 1000);
    }
    
    // Handle string or number
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      return Math.floor(parsedDate.getTime() / 1000);
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

// Update the generateUserHash function to prioritize user_id
export const generateUserHash = async (userId, email) => {
  try {
    // Always use userId if available, fallback to email only if userId is not present
    const valueToHash = userId?.toString().trim() || email?.trim();
    
    if (!valueToHash || !INTERCOM_SECRET_KEY) {
      return null;
    }

    const keyData = stringToUint8Array(INTERCOM_SECRET_KEY);
    const messageData = stringToUint8Array(valueToHash);

    const cryptoKey = await window.crypto.subtle.importKey(
      'raw',
      keyData,
      { name: 'HMAC', hash: 'SHA-256' },
      false,
      ['sign']
    );

    const signature = await window.crypto.subtle.sign(
      'HMAC',
      cryptoKey,
      messageData
    );

    const hash = arrayBufferToHex(signature);

    return hash;
  } catch (error) {
    return null;
  }
};

const buildCustomAttributes = (user) => {
  return user.userType === 'creator' ? {
    user_type: 'creator',
    profile_picture: user.profilePicture || '',
    bio: user.bio || '',
    location: user.location || '',
    onboarding_completed: user.onboardingCompleted || false,
    skills: user.skills ? user.skills.join(', ') : '',
    join_reason: user.joinReason || '',
    preferred_categories: user.preferredCategories ? user.preferredCategories.join(', ') : '',
    languages: user.languages ? user.languages.join(', ') : '',
    // Social media details
    instagram_url: user.socialMedia?.instagram || '',
    tiktok_url: user.socialMedia?.tiktok || '',
    youtube_url: user.socialMedia?.youtube || '',
    instagram_followers: user.socialStats?.instagramFollowers || 0,
    tiktok_followers: user.socialStats?.tiktokFollowers || 0,
    youtube_subscribers: user.socialStats?.youtubeSubscribers || 0,
    // Metrics
    total_applications: user.totalApplications || 0,
    accepted_applications: user.acceptedApplications || 0,
    credits: user.credits || 0,
    last_active: getUnixTimestamp(user.lastActive),
    registration_completed: user.registrationCompleted || false
  } : {
    user_type: 'brand',
    profile_picture: user.profilePicture || '',
    business_name: user.businessName || '',
    fiscal_code: user.fiscalCode || '',
    venue_name: user.venueName || '',
    venue_type: user.venueType || '',
    venue_city: user.venueDetails?.city || '',
    venue_address: user.venueDetails?.address || '',
    venue_website: user.venueDetails?.website || '',
    venue_opening_hours: user.venueDetails?.openingHours ? user.venueDetails.openingHours.join('; ') : '',
    owner_name: user.ownerName || '',
    owner_email: user.ownerEmail || '',
    owner_phone: user.ownerPhone || '',
    contact_phone: user.contactPhone || '',
    website: user.website || '',
    bio: user.bio || '',
    // Metrics
    total_offers: user.totalOffersCreated || 0,
    active_offers: user.activeOffers || 0,
    credits: user.credits || 0,
    last_active: getUnixTimestamp(user.lastActive),
    registration_completed: user.registrationCompleted || false,
    onboarding_completed: user.onboardingCompleted || false
  };
};

// Update initializeIntercom function to include more custom attributes
export const initializeIntercom = async (user) => {
  if (!user?.id) {
    return;
  }

  try {
    if (typeof window.Intercom !== 'function') {
      return;
    }

    const userHash = await generateUserHash(user.id, user.email);

    if (!userHash) {
      return;
    }

    // First, shutdown any existing instance
    if (window.Intercom) {
      window.Intercom('shutdown');
    }

    const customAttributes = buildCustomAttributes(user);

    const intercomData = {
      app_id: INTERCOM_APP_ID,
      user_id: user.id.toString().trim(),
      email: user.email || '',
      name: user.name || '',
      user_hash: userHash,
      created_at: getUnixTimestamp(user.createdAt),
      custom_attributes: customAttributes
    };

    // Add company data for brands
    if (user.userType === 'brand') {
      intercomData.company = {
        company_id: user.id,
        name: user.businessName || user.venueName || '',
        website: user.website || user.venueDetails?.website || '',
        industry: user.venueType || '',
        created_at: getUnixTimestamp(user.createdAt)
      };
    }

    window.Intercom('boot', intercomData);
  } catch (error) {
    throw error;
  }
};

// Update the updateIntercom function to use the same custom attributes structure
export const updateIntercom = async (user) => {
  const userId = user?.id || user?.user_id;
  if (!userId) {
    return;
  }

  try {
    const userHash = await generateUserHash(userId);

    if (!userHash) {
      return;
    }

    const customAttributes = buildCustomAttributes(user);

    const intercomData = {
      user_id: userId.toString().trim(),
      email: user.email || '',
      name: user.name || '',
      user_hash: userHash,
      created_at: getUnixTimestamp(user.createdAt),
      custom_attributes: customAttributes
    };

    // Add company data for brands
    if (user.userType === 'brand') {
      intercomData.company = {
        company_id: userId,
        name: user.businessName || user.venueName || '',
        website: user.website || user.venueDetails?.website || '',
        industry: user.venueType || ''
      };
    }

    window.Intercom('update', intercomData);
  } catch (error) {
    throw error;
  }
};

export const migrateExistingUsers = async () => {
  try {
    // Get all creators
    const creatorSnapshot = await getDocs(collection(db, 'creators'));
    for (const doc of creatorSnapshot.docs) {
      const userData = doc.data();
      updateIntercom({
        id: doc.id,
        ...userData
      });
    }

    // Get all brands
    const brandSnapshot = await getDocs(collection(db, 'brands'));
    for (const doc of brandSnapshot.docs) {
      const userData = doc.data();
      updateIntercom({
        id: doc.id,
        ...userData
      });
    }

  } catch (error) {
    throw error;
  }
};

export const updateUserIntercom = async (userId) => {
  try {
    // Try to get detailed user data from creators collection
    let userDoc = await getDoc(doc(db, 'creators', userId));
    let userType = 'creator';

    // If not found in creators, try brands collection
    if (!userDoc.exists()) {
      userDoc = await getDoc(doc(db, 'brands', userId));
      userType = 'brand';
    }

    if (!userDoc.exists()) {
      throw new Error('User not found in either collection');
    }

    // Get detailed user data including metrics
    const detailedUserData = await getDetailedUserData(userId, userType);
    
    // Update Intercom with the detailed data
    await updateIntercom({
      id: userId,
      userType,
      ...detailedUserData
    });

  } catch (error) {
    throw error; // Propagate error to caller
  }
};

// Update the syncAllUsersToIntercom function
export const syncAllUsersToIntercom = async (onProgress) => {
  const syncResults = {
    creators: { total: 0, success: 0, failed: 0, details: [] },
    brands: { total: 0, success: 0, failed: 0, details: [] }
  };

  try {
    const creatorsSnapshot = await getDocs(collection(db, 'creators'));
    const brandsSnapshot = await getDocs(collection(db, 'brands'));

    syncResults.creators.total = creatorsSnapshot.size;
    syncResults.brands.total = brandsSnapshot.size;

    // Sync creators
    for (const doc of creatorsSnapshot.docs) {
      try {
        const userData = await getDetailedUserData(doc.id, 'creator');
        const intercomData = {
          user_id: userData.id,
          email: userData.email,
          name: userData.name,
          user_hash: await generateUserHash(userData.id),
          created_at: getUnixTimestamp(userData.createdAt),
          custom_attributes: {
            user_type: 'creator',
            profile_picture: userData.profilePicture || '',
            bio: userData.bio || '',
            location: userData.location || '',
            onboarding_completed: userData.onboardingCompleted || false,
            skills: userData.skills ? userData.skills.join(', ') : '',
            instagram_url: userData.socials?.instagram || '',
            tiktok_url: userData.socials?.tiktok || '',
            youtube_url: userData.socials?.youtube || '',
            instagram_followers: userData.socialStats?.instagramFollowers || 0,
            tiktok_followers: userData.socialStats?.tiktokFollowers || 0,
            youtube_subscribers: userData.socialStats?.youtubeSubscribers || 0,
            total_applications: userData.totalApplications || 0,
            accepted_applications: userData.acceptedApplications || 0
          }
        };

        // Notify progress with current user details
        if (onProgress) {
          onProgress({
            type: 'creator',
            current: syncResults.creators.success + syncResults.creators.failed + 1,
            total: syncResults.creators.total,
            results: syncResults,
            currentUser: {
              userType: 'creator',
              email: userData.email,
              name: userData.name,
              status: 'success',
              syncedData: intercomData
            }
          });
        }

        await updateIntercom(intercomData);
        syncResults.creators.success++;
        syncResults.creators.details.push({
          id: doc.id,
          email: userData.email,
          status: 'success'
        });
      } catch (error) {
        syncResults.creators.failed++;
        syncResults.creators.details.push({
          id: doc.id,
          error: error.message,
          status: 'failed'
        });
        if (onProgress) {
          onProgress({
            type: 'creator',
            current: syncResults.creators.success + syncResults.creators.failed + 1,
            total: syncResults.creators.total,
            results: syncResults,
            currentUser: {
              userType: 'creator',
              email: doc.data().email,
              status: 'failed',
              error: error.message
            }
          });
        }
      }
    }

    // Sync brands
    for (const doc of brandsSnapshot.docs) {
      try {
        const userData = await getDetailedUserData(doc.id, 'brand');
        const intercomData = {
          user_id: userData.id,
          email: userData.email,
          name: userData.name,
          user_hash: await generateUserHash(userData.id),
          created_at: getUnixTimestamp(userData.createdAt),
          company: {
            company_id: userData.id,
            name: userData.businessName || userData.venueName || '',
            website: userData.website || userData.venueDetails?.website || '',
            industry: userData.venueType || ''
          },
          custom_attributes: {
            user_type: 'brand',
            profile_picture: userData.profilePicture || '',
            business_name: userData.businessName || '',
            fiscal_code: userData.fiscalCode || '',
            venue_name: userData.venueName || '',
            venue_type: userData.venueType || '',
            venue_city: userData.venueDetails?.city || '',
            venue_address: userData.venueDetails?.address || '',
            owner_name: userData.ownerName || '',
            owner_email: userData.ownerEmail || '',
            owner_phone: userData.ownerPhone || '',
            total_offers: userData.totalOffersCreated || 0,
            active_offers: userData.activeOffers || 0,
            onboarding_completed: userData.onboardingCompleted || false
          }
        };

        // Notify progress with current user details
        if (onProgress) {
          onProgress({
            type: 'brand',
            current: syncResults.brands.success + syncResults.brands.failed + 1,
            total: syncResults.brands.total,
            results: syncResults,
            currentUser: {
              userType: 'brand',
              email: userData.email,
              name: userData.name,
              status: 'success',
              syncedData: intercomData
            }
          });
        }

        await updateIntercom(intercomData);
        syncResults.brands.success++;
        syncResults.brands.details.push({
          id: doc.id,
          email: userData.email,
          status: 'success'
        });
      } catch (error) {
        syncResults.brands.failed++;
        syncResults.brands.details.push({
          id: doc.id,
          error: error.message,
          status: 'failed'
        });
        if (onProgress) {
          onProgress({
            type: 'brand',
            current: syncResults.brands.success + syncResults.brands.failed + 1,
            total: syncResults.brands.total,
            results: syncResults,
            currentUser: {
              userType: 'brand',
              email: doc.data().email,
              name: doc.data().name,
              status: 'failed',
              error: error.message
            }
          });
        }
      }
    }

    return syncResults;
  } catch (error) {
    throw error;
  }
};

export const getDetailedUserData = async (userId, userType) => {
  try {
    const docRef = doc(db, `${userType}s`, userId);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      throw new Error('User not found');
    }

    const userData = {
      id: userId,
      userType,
      ...docSnap.data()
    };

    // Get additional metrics
    if (userType === 'creator') {
      const applicationsSnapshot = await getDocs(
        query(collection(db, 'applications'), where('creatorId', '==', userId))
      );
      userData.totalApplications = applicationsSnapshot.size;
      userData.acceptedApplications = applicationsSnapshot.docs.filter(
        doc => doc.data().status === 'accepted'
      ).length;
    }

    if (userType === 'brand') {
      const offersSnapshot = await getDocs(
        query(collection(db, 'offers'), where('brandId', '==', userId))
      );
      userData.totalOffersCreated = offersSnapshot.size;
      userData.activeOffers = offersSnapshot.docs.filter(
        doc => doc.data().status === 'active'
      ).length;
    }

    return userData;
  } catch (error) {
    throw error;
  }
};
