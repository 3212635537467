import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { GlobalProvider } from './context/GlobalContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import { ToastProvider } from './context/ToastContext';
import { LoadingProvider } from './context/LoadingContext';
import ErrorBoundary from './components/ErrorBoundary';
import Spinner from './components/Spinner';
import { useLoading } from './context/LoadingContext';
import Layout from './components/Layout';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import OnboardingScreen from './screens/OnboardingScreen';
import DashboardScreen from './screens/DashboardScreen';
import CreatorOfferScreen from './screens/CreatorOfferScreen';
import BrandOfferScreen from './screens/BrandOfferScreen';
import ApplicationsScreen from './screens/ApplicationsScreen';
import AppliedOffersScreen from './screens/AppliedOffersScreen';
import ProfileScreen from './screens/ProfileScreen';
import AnalyticsScreen from './screens/AnalyticsScreen';
import MessagesScreen from './screens/MessagesScreen';
import BuyCreditsScreen from './screens/BuyCreditsScreen';
import CreatorProfileScreen from './screens/CreatorProfileScreen';
import BrandProfileScreen from './screens/BrandProfileScreen';
import AdminPanel from './screens/AdminPanel';
import PrivateRoute from './components/PrivateRoute';
import NotificationHandler from './components/NotificationHandler';
import { initializeIntercom } from './utils/intercom';
import PendingApprovalScreen from './screens/PendingApprovalScreen';
import LogoffScreen from './screens/LogoffScreen';
import BrandDashboard from './screens/BrandDashboard';
import CreatorDashboard from './screens/CreatorDashboard';
import CreatorPersonalScreen from './screens/CreatorPersonalScreen';
import posthog from './utils/posthog';

function AppContent() {
  const { currentUser, userType, loading } = useAuth();
  const { isLoading } = useLoading();
  const location = useLocation();

  useEffect(() => {
    posthog.capture('$pageview', {
      path: location.pathname,
      userType: userType,
      userId: currentUser?.uid
    });
  }, [location, userType, currentUser]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {isLoading && <Spinner />}
      <NotificationHandler />
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/logoff" element={<LogoffScreen />} />
        <Route path="/onboarding" element={<OnboardingScreen />} />
        <Route path="/pending-approval" element={<PendingApprovalScreen />} />
        
        {/* Public creator profile route */}
        <Route path="/creator/:username" element={<CreatorPersonalScreen />} />

        {/* Admin route - separate from other protected routes */}
        <Route 
          path="/admin" 
          element={
            <PrivateRoute adminOnly>
              <AdminPanel />
            </PrivateRoute>
          } 
        />

        {/* Protected routes wrapper */}
        <Route element={<PrivateRoute><Layout /></PrivateRoute>}>
          <Route path="/dashboard" element={userType === 'creator' ? <CreatorDashboard /> : <BrandDashboard />} />
          <Route path="/offers" element={<CreatorOfferScreen />} />
          <Route path="/manage-offers" element={<BrandOfferScreen />} />
          <Route path="/applications" element={<ApplicationsScreen />} />
          <Route path="/applied-offers" element={<AppliedOffersScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/analytics" element={<AnalyticsScreen />} />
          <Route path="/messages" element={<MessagesScreen />} />
          <Route path="/buy-credits" element={<BuyCreditsScreen />} />
          <Route path="/creator-profile" element={<CreatorProfileScreen />} />
          <Route path="/brand-profile" element={<BrandProfileScreen />} />
        </Route>

        {/* Default routes */}
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <ToastProvider>
            <GlobalProvider>
              <LoadingProvider>
                <AppContent />
              </LoadingProvider>
            </GlobalProvider>
          </ToastProvider>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
