import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../context/AuthContext';
import Header from '../components/Header';
import { useToast } from '../context/ToastContext';
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import 'react-image-crop/dist/ReactCrop.css';
import { useGlobalContext } from '../context/GlobalContext';
import { Eye } from 'lucide-react';
import { Link } from 'react-router-dom';
import { track } from '../utils/posthog';
import { updateUserIntercom, getDetailedUserData, updateIntercom } from '../utils/intercom';

const CreatorProfileScreen = () => {
  const { currentUser } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({
    name: '',
    bio: '',
    profilePicture: '',
    skills: [],
    socials: {
      instagram: '',
      tiktok: '',
      youtube: ''
    },
    socialStats: {
      instagramFollowers: 0,
      tiktokFollowers: 0,
      youtubeSubscribers: 0
    },
    shippingInfo: {
      address: '',
      isPublic: false
    }
  });
  const [newSkill, setNewSkill] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [uploading, setUploading] = useState(false);
  const imageRef = useRef(null);
  const { setRefreshHeaderCount } = useGlobalContext() || {};
  const [profileUrl, setProfileUrl] = useState('');

  // Add these base input classes as constants
  const inputBaseClasses = "block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed";
  const textareaBaseClasses = "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500";
  const labelBaseClasses = "block mb-2 text-sm font-medium text-gray-900";
  const buttonBaseClasses = "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none";

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const docRef = doc(db, 'creators', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProfileData({
            ...profileData,
            ...docSnap.data()
          });
        }
        setLoading(false);
      } catch (error) {
        addToast('Error loading profile', 'error');
        setLoading(false);
      }
    };

    fetchProfile();
  }, [currentUser]);

  // Add this effect to generate the profile URL
  useEffect(() => {
    if (profileData.name) {
      const urlName = profileData.name
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-]/g, '');
      
      const uniqueId = currentUser.uid.slice(-6);
      const newProfileUrl = `/creator/${urlName}-${uniqueId}`;
      console.log('Generated profile URL:', newProfileUrl); // Debug log
      setProfileUrl(newProfileUrl);
    }
  }, [profileData.name, currentUser.uid]);

  const handleSave = async () => {
    try {
      const name = profileData.name.trim();
      const normalizedName = name
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-]/g, '');
      
      const uniqueId = currentUser.uid.slice(-6);
      const uniqueNormalizedName = `${normalizedName}-${uniqueId}`;

      const docRef = doc(db, 'creators', currentUser.uid);
      const updateData = {
        ...profileData,
        normalizedName: uniqueNormalizedName,
        username: uniqueNormalizedName,
        uid: currentUser.uid, // Explicitly store the full UID
        name: name,
        updatedAt: new Date()
      };

      await updateDoc(docRef, updateData);
      
      // Verify the update
      const updatedDoc = await getDoc(docRef);
      
      track('profile_updated', {
        userType: 'creator',
        updatedFields: Object.keys(profileData)
      });

      // Update Intercom data after profile update
      await updateUserIntercom(currentUser.uid);

      // Get detailed user data and update Intercom
      const detailedData = await getDetailedUserData(currentUser.uid, 'creator');
      await updateIntercom(detailedData);

      addToast('Profile updated successfully', 'success');
    } catch (error) {
      track('profile_update_error', {
        error: error.message
      });
      addToast('Error updating profile', 'error');
    }
  };

  const handleAddSkill = () => {
    if (newSkill.trim() && !profileData.skills.includes(newSkill.trim())) {
      setProfileData({
        ...profileData,
        skills: [...profileData.skills, newSkill.trim()]
      });
      setNewSkill('');
    }
  };

  const handleRemoveSkill = (skillToRemove) => {
    setProfileData({
      ...profileData,
      skills: profileData.skills.filter(skill => skill !== skillToRemove)
    });
  };

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageSrc(reader.result);
        setIsModalOpen(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    const newCrop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        1,
        width,
        height
      ),
      width,
      height
    );
    setCrop(newCrop);
  }

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setImageSrc(null);
    setCrop(undefined);
    setCompletedCrop(null);
  }, []);

  const handleSaveImage = useCallback(async () => {
    if (!completedCrop || !imageRef.current) {
      return;
    }

    setUploading(true);
    try {
      const croppedImageBlob = await getCroppedImg(imageRef.current, completedCrop);
      
      if (!croppedImageBlob) {
        throw new Error('Failed to create image blob');
      }
      
      const storageRef = ref(storage, `profile-pictures/${currentUser.uid}`);
      const uploadResult = await uploadBytes(storageRef, croppedImageBlob);

      const downloadURL = await getDownloadURL(storageRef);

      if (!downloadURL) {
        throw new Error('Failed to get download URL');
      }

      const docRef = doc(db, 'creators', currentUser.uid);
      await updateDoc(docRef, {
        profilePicture: downloadURL
      });

      setProfileData(prev => ({
        ...prev,
        profilePicture: downloadURL
      }));

      if (typeof setRefreshHeaderCount === 'function') {
        setRefreshHeaderCount(prev => prev + 1);
      }

      handleCloseModal();
      addToast('Profile picture updated successfully', 'success');
    } catch (error) {
      addToast(`Error uploading image: ${error.message}`, 'error');
    } finally {
      setUploading(false);
    }
  }, [completedCrop, currentUser.uid, addToast, handleCloseModal, setRefreshHeaderCount]);

  const ImageCropModal = useMemo(() => {
    if (!isModalOpen) return null;

    return (
      <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-75">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl w-full max-w-2xl">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                  Crop Profile Picture
                </h3>
                <div className="mt-2">
                  {imageSrc && (
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={1}
                      circularCrop
                      className="max-h-[60vh]"
                    >
                      <img
                        ref={imageRef}
                        alt="Crop me"
                        src={imageSrc}
                        onLoad={onImageLoad}
                        className="max-w-full"
                      />
                    </ReactCrop>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-2">
              <button
                type="button"
                className={`${buttonBaseClasses} ${uploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleSaveImage}
                disabled={uploading || !completedCrop}
              >
                {uploading ? 'Saving...' : 'Save'}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-lg border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [isModalOpen, imageSrc, crop, completedCrop, uploading, handleSaveImage, handleCloseModal]);

  useEffect(() => {
    document.title = 'Edit Profile | Behave';
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header userType="creator" />
        <div className="animate-pulse">
          {/* Add loading skeleton here */}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header userType="creator" />
      <main className="pt-28 md:pt-20 pb-8">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white shadow-sm rounded-lg">
            {/* Header Section with View Profile Button */}
            <div className="border-b border-gray-200 px-6 py-4 flex justify-between items-center">
              <h1 className="text-2xl font-bold text-gray-900">Edit Profile</h1>
              {profileUrl && (
                <Link 
                  to={profileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <Eye className="w-4 h-4 mr-2" />
                  View Public Profile
                </Link>
              )}
            </div>

            {/* Content Section */}
            <div className="px-6 py-6 space-y-8">
              {/* Basic Information */}
              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Basic Information</h2>
                <div className="space-y-4">
                  {/* Profile Picture */}
                  <div className="space-y-4">
                    <label className={labelBaseClasses}>Profile Picture</label>
                    <div className="flex items-center space-x-6">
                      <div className="relative inline-block min-w-24">
                        <img
                          src={profileData.profilePicture || 'https://via.placeholder.com/150'}
                          alt="Profile"
                          className="h-24 w-24 rounded-full object-cover ring-2 ring-white shadow-md"
                        />
                        <label
                          htmlFor="profile-upload"
                          className="absolute bottom-0 right-0 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 w-4">
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                          <input
                            id="profile-upload"
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={onSelectFile}
                          />
                        </label>
                      </div>
                      <div className="text-sm text-gray-600">
                        <p>Upload a new profile picture</p>
                        <p>Recommended: Square image, at least 400x400px</p>
                      </div>
                    </div>
                  </div>

                  {/* Name */}
                  <div>
                    <label className={labelBaseClasses}>Full Name</label>
                    <input
                      type="text"
                      value={profileData.name}
                      onChange={(e) => setProfileData({...profileData, name: e.target.value})}
                      className={inputBaseClasses}
                      placeholder="Enter your name"
                    />
                  </div>

                  {/* Bio */}
                  <div>
                    <label className={labelBaseClasses}>Bio</label>
                    <textarea
                      value={profileData.bio}
                      onChange={(e) => setProfileData({...profileData, bio: e.target.value})}
                      rows={3}
                      className={textareaBaseClasses}
                      placeholder="Tell us about yourself"
                    />
                  </div>
                </div>
              </section>

              {/* Social Media */}
              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Social Media</h2>
                <div className="space-y-6">
                  {/* Instagram */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className={`${labelBaseClasses} flex items-center gap-2`}>
                        <FaInstagram className="text-pink-500" /> Instagram Profile
                      </label>
                      <input
                        type="url"
                        value={profileData.socials.instagram}
                        onChange={(e) => setProfileData({
                          ...profileData,
                          socials: {...profileData.socials, instagram: e.target.value}
                        })}
                        className={inputBaseClasses}
                        placeholder="https://instagram.com/username"
                      />
                    </div>
                    <div>
                      <label className={labelBaseClasses}>Instagram Followers</label>
                      <input
                        type="number"
                        value={profileData.socialStats.instagramFollowers}
                        onChange={(e) => setProfileData({
                          ...profileData,
                          socialStats: {...profileData.socialStats, instagramFollowers: parseInt(e.target.value) || 0}
                        })}
                        className={inputBaseClasses}
                        min="0"
                        placeholder="0"
                      />
                    </div>
                  </div>

                  {/* TikTok */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className={`${labelBaseClasses} flex items-center gap-2`}>
                        <FaTiktok className="text-black" /> TikTok Profile
                      </label>
                      <input
                        type="url"
                        value={profileData.socials.tiktok}
                        onChange={(e) => setProfileData({
                          ...profileData,
                          socials: {...profileData.socials, tiktok: e.target.value}
                        })}
                        className={inputBaseClasses}
                        placeholder="https://tiktok.com/@username"
                      />
                    </div>
                    <div>
                      <label className={labelBaseClasses}>TikTok Followers</label>
                      <input
                        type="number"
                        value={profileData.socialStats.tiktokFollowers}
                        onChange={(e) => setProfileData({
                          ...profileData,
                          socialStats: {...profileData.socialStats, tiktokFollowers: parseInt(e.target.value) || 0}
                        })}
                        className={inputBaseClasses}
                        min="0"
                        placeholder="0"
                      />
                    </div>
                  </div>

                  {/* YouTube */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className={`${labelBaseClasses} flex items-center gap-2`}>
                        <FaYoutube className="text-red-600" /> YouTube Channel
                      </label>
                      <input
                        type="url"
                        value={profileData.socials.youtube}
                        onChange={(e) => setProfileData({
                          ...profileData,
                          socials: {...profileData.socials, youtube: e.target.value}
                        })}
                        className={inputBaseClasses}
                        placeholder="https://youtube.com/c/channel"
                      />
                    </div>
                    <div>
                      <label className={labelBaseClasses}>YouTube Subscribers</label>
                      <input
                        type="number"
                        value={profileData.socialStats.youtubeSubscribers}
                        onChange={(e) => setProfileData({
                          ...profileData,
                          socialStats: {...profileData.socialStats, youtubeSubscribers: parseInt(e.target.value) || 0}
                        })}
                        className={inputBaseClasses}
                        min="0"
                        placeholder="0"
                      />
                    </div>
                  </div>
                </div>
              </section>

              {/* Skills Section */}
              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Skills</h2>
                <div className="space-y-4">
                  <div className="flex flex-wrap gap-2">
                    {profileData.skills.map((skill, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center px-3 py-1.5 rounded-lg text-sm font-medium bg-blue-100 text-blue-800"
                      >
                        {skill}
                        <button
                          onClick={() => handleRemoveSkill(skill)}
                          className="ml-2 text-blue-600 hover:text-blue-900"
                          aria-label={`Remove ${skill}`}
                        >
                          ×
                        </button>
                      </span>
                    ))}
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={newSkill}
                      onChange={(e) => setNewSkill(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && handleAddSkill()}
                      className={inputBaseClasses}
                      placeholder="Add a skill"
                    />
                    <button
                      onClick={handleAddSkill}
                      className={buttonBaseClasses}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </section>

              {/* Shipping Information */}
              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Shipping Information</h2>
                <div className="space-y-4">
                  <div>
                    <label className={labelBaseClasses}>Address</label>
                    <textarea
                      value={profileData.shippingInfo.address}
                      onChange={(e) => setProfileData({
                        ...profileData,
                        shippingInfo: {...profileData.shippingInfo, address: e.target.value}
                      })}
                      rows={3}
                      className={textareaBaseClasses}
                      placeholder="Enter your shipping address"
                    />
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="isPublic"
                      checked={profileData.shippingInfo.isPublic}
                      onChange={(e) => setProfileData({
                        ...profileData,
                        shippingInfo: {...profileData.shippingInfo, isPublic: e.target.checked}
                      })}
                      className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                    />
                    <label htmlFor="isPublic" className="ml-2 text-sm text-gray-700">
                      Make shipping address visible on my public profile
                    </label>
                  </div>
                </div>
              </section>
            </div>

            {/* Footer */}
            <div className="border-t border-gray-200 px-6 py-4">
              <div className="flex justify-end">
                <button
                  onClick={handleSave}
                  className={buttonBaseClasses}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
        
        {/* Add the ImageCropModal at the end of the main content */}
        {ImageCropModal}
      </main>
    </div>
  );
};

// Move getCroppedImg outside the component
function getCroppedImg(image, crop) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');

  // Set proper canvas dimensions
  canvas.width = crop.width;
  canvas.height = crop.height;

  // Draw the cropped image
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // Return promise with blob
  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Canvas to Blob failed'));
            return;
          }
          resolve(blob);
        },
        'image/jpeg',
        0.95  // Slightly reduced quality for better performance
      );
    } catch (e) {
      reject(e);
    }
  });
}

export default CreatorProfileScreen;
